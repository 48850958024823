import { Pagination, Tooltip } from "antd";
import SortingArrow from "../../../Common/SortingArrow";
import { useEffect, useState } from "react";
import Assign from "../../../Modal/Assign";
import { useNavigate } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import DateHelper from "../../../Helpers/lib/DateHelper";
import {
  AssignAgent,
  AssignSelf,
  getAssignAgent,
} from "../../../Axios/services/LiveChatQue";
import { getAgentOptions } from "../../../Axios/services/ManageRequest";
import { changeLoader } from "../../../Redux/reducers/loader";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../Modal/ConfirmationModal";
import EmptyComponent from "../../Empty";
import { checkPermission } from "../../../Helpers/Functions";
import addDeleteGetLocalStorage from "../../../Axios/addDeleteGetLocalStorage";
import { storageKeys } from "../../../Axios/Enum";
import { jwtDecode } from "jwt-decode";

const ChatQueue = ({
  chatQueue,
  handleGetAllchatqueue,
  page,
  limit,
  pageCount,
  itemTotal,
  handlePageChange,
  setLimit,
  handlePick,
}) => {
  const navigate = useNavigate();
  let token = addDeleteGetLocalStorage(
    storageKeys.ADMIN_TOKEN,
    {},
    "get",
    "single"
  );
  token = jwtDecode(token);

  const [pickId, setPickId] = useState(null);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [agents, setAgents] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [chatRoomId, setchatRoomId] = useState(null);
  const dispatch = useDispatch();

  const handleAssignOk = () => {
    setIsAssignModalOpen(false);
  };

  const handlegetAssignAgent = async () => {
    dispatch(changeLoader(true));
    try {
      let responce = await getAssignAgent();
      if (responce.ack) {
        setAgents(responce?.data);
      }
      dispatch(changeLoader(false));
    } catch (err) {
      dispatch(changeLoader(false));
      console.error(err);
    }
  };

  const handleAssign = async () => {
    try {
      let data = {
        chat_room_id: chatRoomId,
        agent_id: selectedRadio,
      };
      let responce = await AssignAgent(data);
      if (responce.ack) {
        setIsAssignModalOpen(false);
        handleGetAllchatqueue();
      }
      toast[responce.ack ? "success" : "error"](responce.message, {
        limit: 1,
        toastId: "forgotPassword" + (responce.ack ? "Success" : "Error"),
      });
      setIsAssignModalOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePickOk = () => {
    handlePick(pickId);
    setIsDeleteModalOpen(false);
  };
  const handlePickCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const handleAssignCancel = () => {
    setIsAssignModalOpen(false);
  };

  return (
    <>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 bg-gray-50">
            <tr>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Client ID
                {/* <SortingArrow /> */}
              </th>
              <th scope="col" className="px-3 py-3">
                Client Name
                {/* <SortingArrow /> */}
              </th>
              <th scope="col" className="px-3 py-3">
                Email Address
                {/* <SortingArrow /> */}
              </th>
              <th scope="col" className="px-3 py-3">
                Request Date & time
                {/* <SortingArrow /> */}
              </th>
              {checkPermission("live_chat", "accept-close-chat") && (
                <th
                  scope="col"
                  className="px-3 py-3 text-center whitespace-nowrap"
                >
                  Action
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {chatQueue?.rows?.length > 0 ? (
              chatQueue?.rows?.map((item) => (
                <tr key={item?.id} className="bg-white border-b">
                  <th
                    scope="row"
                    className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top"
                  >
                    {item?.client_id}
                  </th>
                  <td className="px-3 py-4 vertical-top capitalize">
                    {item?.client?.full_name}
                  </td>
                  <td className="px-3 py-4 vertical-top">
                    {item?.client?.email}
                  </td>
                  <td className="px-3 py-4 vertical-top">
                    {DateHelper.format(item?.createdAt, "d/m/Y h:i A")}
                  </td>
                  {checkPermission("live_chat", "accept-close-chat") && (
                    <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                      <span
                        className="underline cursor-pointer mx-1 font-semibold"
                        onClick={() => {
                          setIsDeleteModalOpen(true);
                          setPickId(item?.id);
                        }}
                      >
                        Pick
                      </span>
                      {token?.role === "super_admin" && (
                        <span
                          className="underline cursor-pointer mx-1 font-semibold"
                          onClick={() => {
                            setIsAssignModalOpen(true);
                            setchatRoomId(item?.id);
                            handlegetAssignAgent();
                          }}
                        >
                          Assign
                        </span>
                      )}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  <EmptyComponent description="No chat queue found" />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="text-right mt-4">
        {pageCount > 1 && (
          <div className="flex justify-end mt-4">
            <Pagination
              defaultCurrent={page}
              defaultPageSize={limit}
              total={itemTotal}
              onChange={handlePageChange}
              pageSizeOptions={[10, 25, 50, 100]}
              onShowSizeChange={(current, size) => {
                limit = size;
                setLimit(limit);
                handleGetAllchatqueue();
              }}
            />
          </div>
        )}
      </div>
      <Assign
        agents={agents}
        handleAssign={handleAssign}
        isOpen={isAssignModalOpen}
        onOk={handleAssignOk}
        onCancel={handleAssignCancel}
        selectedRadio={selectedRadio}
        setSelectedRadio={setSelectedRadio}
      />
      <ConfirmationModal
        ConfirmationHeading="Pick"
        ConfirmationParagraph="Are you sure you want to pick?"
        isOpen={isDeleteModalOpen}
        onOk={handlePickOk}
        onCancel={handlePickCancel}
      />
    </>
  );
};

export default ChatQueue;
