import { Navigate, Outlet} from "react-router-dom"
import addDeleteGetLocalStorage from "../Axios/addDeleteGetLocalStorage"
import { storageKeys } from "../Axios/Enum"
import { loginData } from "../Helpers/Functions"
const AuthGuard = () => {

    let userData = loginData()

    return <>
        {(addDeleteGetLocalStorage(storageKeys.ADMIN_TOKEN , {}, "get", "single"))   ? (userData?.reset_password != 0 ? <Outlet /> : <Navigate to="/reset-password" />) : <Navigate to="/" />}
    </>
}


export default AuthGuard;
