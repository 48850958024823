import React from "react";
import { Col, Modal, Row, Typography } from "antd";
import DateHelper from "../../Helpers/lib/DateHelper";
const ViewAuthorization = ({ isOpen, onOk, onCancel, authorizationDetail }) => {
  return (
    <>
      <Modal
        title="Authorization"
        centered
        open={isOpen}
        onCancel={onCancel}
        onOk={onOk}
        width={500}
        footer={null}
      >
        <Typography className="text-sm mb-4">
          <Row gutter={[40, 16]}>
            <Col className="gutter-row flex items-start justify-start" lg={24}>
              <div className="text-sm font-medium text-black w-[180px]">
                Client name :{" "}
              </div>
              <div className="text-sm">
                {authorizationDetail?.client?.full_name
                  ? authorizationDetail?.client?.full_name
                  : "--"}
              </div>
            </Col>
            <Col className="gutter-row flex items-start justify-start" lg={24}>
              <div className="text-sm font-medium text-black w-[180px]">
                Signed Date & time :{" "}
              </div>
              <div className="text-sm">
                {authorizationDetail?.signed_on
                  ? DateHelper.format(
                      authorizationDetail?.signed_on,
                      "m/d/Y h:i A"
                    )
                  : "--"}
              </div>
            </Col>
            <Col className="gutter-row flex items-start justify-start" lg={24}>
              <div className="text-sm font-medium text-black w-[180px]">
                IP Address :{" "}
              </div>
              <div className="text-sm">{authorizationDetail?.ip ? authorizationDetail?.ip : "--"}</div>
            </Col>
            <Col className="gutter-row flex items-start justify-start" lg={24}>
              <div className="text-sm font-medium text-black w-[180px]">
              Device Id :{" "}
              </div>
              <div className="text-sm">{authorizationDetail?.device_id ? authorizationDetail?.device_id : "--"}</div>
            </Col>
            {(authorizationDetail?.signature && authorizationDetail?.signature.includes('http'))  && <Col className="gutter-row flex items-start justify-start" lg={24}>
              <div className="text-sm font-medium text-black w-[180px]">
                Signature :
              </div>
                <div className="text-sm">
                  <img
                    src={authorizationDetail?.signature}
                    alt="Signature"
                    className="w-[100px] h-auto"
                  />
                </div>
            </Col>}
          </Row>
        </Typography>
      </Modal>
    </>
  );
};
export default ViewAuthorization;
