import axiosInstance from "../AxiosInstance";

export const getAllChatRooms = (params) => {
  return axiosInstance.get("/admin/chat/get-agent-chat", { params });
};

export const getchatRoom = (id) => {
  return axiosInstance.get(`/admin/chat/get-agent-chat/${id}`);
};

export const getChatRoomMessage = (id) => {
  return axiosInstance.get(`/admin/chat/get/agent-messages/${id}`);
};

export const sendMessage = (data) => {
  return axiosInstance.post("/admin/chat/send-agent-message", data);
};

export const getUnreadCount = () => {
  return axiosInstance.get(`/admin/chat/agent-unread-count`);
};

export const markchatMessageAsRead = (id) => {
  return axiosInstance.get(`/admin/chat/agent-mark-read/${id}`);
};
