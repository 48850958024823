import { useEffect, useState } from "react";
import { Button, Pagination, Switch } from "antd";
import SortingArrow from "../../../Common/SortingArrow";
import SendAuthorization from "../../../Modal/SendAuthorization";
import { checkPermission, getStatusColor } from "../../../Helpers/Functions";
import { useParams } from "react-router-dom";
import {
  getAuthorization,
  getAuthorizationDetail,
} from "../../../Axios/services/ClientManagement";
import DateHelper from "../../../Helpers/lib/DateHelper";
import { changeLoader } from "../../../Redux/reducers/loader";
import { useDispatch } from "react-redux";
import ViewAuthorization from "../../../Modal/ViewAuthorization";
import EmptyComponent from "../../Empty";

const Authorization = ({ responcess }) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [authorization, setAuthorizations] = useState([]);
  let [page, setPage] = useState(1);
  let [limit, setLimit] = useState(3);
  const [itemTotal, setItemTotal] = useState(0);
  const [isViewAuthorization, setIsViewAuthorization] = useState(false);
  const [authorizationDetail, setAuthorizationDetail] = useState({});
  let [pageCount, setPageCount] = useState(0);
  const [sorting, setSorting] = useState({
    order: "DESC",
    sort_by: "id",
  });
  const handleSort = (order, sortby) => {
    let obj = {
      order: order,
      sort_by: sortby,
    };
    setSorting(obj);
  };

  const handlePageChange = (newPage) => {
    page = newPage;
    setPage(newPage);
    handleGetAuthorization();
  };

  const handleDeleteOk = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
  };
  const handleViewAuthorizationOk = async () => {
    setIsViewAuthorization(false);
  };
  const handleViewAuthorizationCancel = () => {
    setIsViewAuthorization(false);
  };
  const handleGetAuthorization = async () => {
    dispatch(changeLoader(true));
    try {
      let params = { page: page, limit: limit, ...sorting };
      let responce = await getAuthorization(slug, params);
      if (responce.ack) {
        setAuthorizations(responce?.data);
        setItemTotal(responce?.data?.count);
        setPageCount(Math.ceil(responce?.data?.count / limit));
      }
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
      console.log(error);
    }
  };

  const handleGetAuthorizationDetail = async (id) => {
    dispatch(changeLoader(true));
    try {
      let responce = await getAuthorizationDetail(id);
      if (responce.ack) {
        setAuthorizationDetail(responce?.data);
      }
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetAuthorization();
  }, [sorting]);

  return (
    <>
      <div className="flex justify-end items-center my-5">
        <div className="flex gap-5 mr-[10px]">
          <div
            className={`w-[100px] py-2 px-3 text-xs rounded-lg text-center capitalize ${getStatusColor(
              responcess?.friendly
            )}`}
          >
            {responcess?.friendly == "no" ? "UnFriendly" : "Friendly"}
          </div>
        </div>
        {/* {checkPermission("manage_client", "send-suthorization") && (
          <div>
            <Button type="primary" onClick={() => setIsDeleteModalOpen(true)}>
              Send Authorisation
            </Button>
          </div>
        )} */}
      </div>
      <table className="w-full  text-left	text-sm text-gray-500">
        <thead className=" text-gray-700 capitalize text-xs	 bg-gray-50">
          <tr>
            <th
              scope="col"
              className="px-3 py-3 whitespace-nowrap"
              title="Appointment Id"
            >
              Unfriendly status received date & time{" "}
              <SortingArrow
                onSort={(order) => {
                  handleSort(order, "authorization_sent_on");
                }}
              />
            </th>
            <th scope="col" className="px-3 py-3" title="Name">
              Clicked on Link?{" "}
              <SortingArrow
                onSort={(order) => {
                  handleSort(order, "link_clicked_on");
                }}
              />
            </th>
            <th scope="col" className="px-3 py-3" title="Name">
              Clicked Date & time{" "}
              <SortingArrow
                onSort={(order) => {
                  handleSort(order, "link_clicked_on");
                }}
              />
            </th>
            <th
              scope="col"
              className="px-3 py-3 whitespace-nowrap"
              title="Appointment Id"
            ></th>
          </tr>
        </thead>
        <tbody>
          {authorization?.rows?.length > 0 ? (
            authorization?.rows?.map((item, index) => {
              return (
                <tr className="bg-white border-b" key={index}>
                  <th
                    scope="row"
                    className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {DateHelper.format(
                      item?.authorization_sent_on,
                      "m/d/Y h:i A"
                    )}
                  </th>
                  <td className="px-3 py-4 vertical-top">
                    {item?.link_clicked_on == null ? "No" : "Yes"}
                  </td>
                  <td className="px-3 py-4 vertical-top">
                    {item?.link_clicked_on ? item?.link_clicked_on : "--"}
                  </td>
                  <td className="px-3 py-4 vertical-top">
                    <Button
                      type="primary"
                      onClick={() => {
                        setIsViewAuthorization(true);
                        handleGetAuthorizationDetail(item?.id);
                      }}
                    >
                      View Authorization
                    </Button>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4} className="text-center">
                <EmptyComponent description="No Authorization Found" />
              </td>
            </tr>
          )}
          {/* <tr className="bg-white border-b">
            <th scope="row" className="px-3 py-4 font-medium text-gray-900 ">
              01/02/2024 09:00 am
            </th>
            <td className="px-3 py-4 vertical-top">Yes</td>
            <td className="px-3 py-4 vertical-top">01/02/2024 10:00 am</td>
          </tr>
          <tr className="bg-white border-b">
            <th scope="row" className="px-3 py-4 font-medium text-gray-900 ">
            01/02/2024 09:00 am
            </th>
            <td className="px-3 py-4 vertical-top">No</td>
            <td className="px-3 py-4 vertical-top">--</td>
          </tr> */}
        </tbody>
      </table>
      {isViewAuthorization && (
        <ViewAuthorization
          isOpen={true}
          onOk={handleViewAuthorizationOk}
          onCancel={handleViewAuthorizationCancel}
          authorizationDetail={authorizationDetail}
        />
      )}
      <SendAuthorization
        isOpen={isDeleteModalOpen}
        onOk={handleDeleteOk}
        onCancel={handleDeleteCancel}
      />
      {pageCount > 1 && (
        <div className="flex justify-end mt-4">
          <Pagination
            className="cursor-pointer"
            defaultCurrent={page}
            defaultPageSize={limit}
            total={itemTotal}
            onChange={handlePageChange}
            pageSizeOptions={[10, 25, 50, 100]}
            onShowSizeChange={(current, size) => {
              limit = size;
              setLimit(limit);
              handleGetAuthorization();
            }}
          />
        </div>
      )}
    </>
  );
};

export default Authorization;
