import React, { useEffect, useState } from "react";
import { Avatar, Badge, Layout, Menu } from "antd";
import {
  DownOutlined,
  UserOutlined,
  UnlockOutlined,
  LoginOutlined,
  BellOutlined,
} from "@ant-design/icons";
import ChangePassword from "../../Modal/ChangePassword";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import Notification from "../../Modal/Notification";
import { useNavigate } from "react-router-dom";
import { logout } from "../../Axios/services/AuthService";
import addDeleteGetLocalStorage from "../../Axios/addDeleteGetLocalStorage";
import { changeLoader } from "../../Redux/reducers/loader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { storageKeys } from "../../Axios/Enum";
import { jwtDecode } from "jwt-decode";
import { getSocket, initializeSocket } from "../../Socket/socket";
import {
  getNotifications,
  getUnreadNotificationCount,
} from "../../Axios/services/Notification";
import { checkPermission, refreshToken } from "../../Helpers/Functions";
import { changeTrigger } from "../../Redux/reducers/trigger";

const { Header } = Layout;
const HeaderComponent = () => {
  const navigate = useNavigate();
  // const profile = useSelector((state) => state.profile.value);
  const trigger = useSelector((state) => state.trigger.value);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [notificationCount, setNotificationCount] = useState(null);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);
  const [notificationDrawerOpen, setNotificationDrawerOpen] = useState(false);
  const UserData = addDeleteGetLocalStorage(
    storageKeys.ADMIN_TOKEN,
    null,
    "get",
    "single"
  );
  const decoded = jwtDecode(UserData);
  const dispatch = useDispatch();
  const showChangePasswordModal = () => {
    setIsChangePasswordModalOpen(true);
  };
  const handleChangePasswordOk = () => {
    setIsChangePasswordModalOpen(false);
  };
  const handleChangePasswordCancel = () => {
    setIsChangePasswordModalOpen(false);
  };

  const showLogoutModal = () => {
    setIsLogoutModalOpen(true);
  };
  const handleLogoutOk = async () => {
    setIsLogoutModalOpen(false);
    dispatch(changeLoader(true));
    try {
      const response = await logout();
      if (response.ack) {
        localStorage.clear();
        navigate("/");
      }
      toast[response.ack ? "success" : "error"](response.message, {
        limit: 1,
        toastId: "forgotPassword" + (response.ack ? "Success" : "Error"),
      });
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
      console.log(error);
    }
  };
  const handleLogoutCancel = () => {
    setIsLogoutModalOpen(false);
  };
  const showNotificationDrawer = () => {
    setNotificationDrawerOpen(true);
  };
  const handleNotificationDrawerClose = () => {
    setNotificationDrawerOpen(false);
  };

  const handlegetUnreadCount = async () => {
    try {
      let response = await getUnreadNotificationCount();
      if (response.ack) {
        setNotificationCount(response?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    handlegetUnreadCount();
  }, []);

  const profilechanged = async () => {
    await refreshToken();
  };

  useEffect(() => {
    if (trigger == "changeProfilePicture") {
      profilechanged();
      setTimeout(() => {
        dispatch(changeTrigger(""));
      }, 2000);
    }
  }, [trigger]);

  useEffect(() => {
    let socket = getSocket();
    const readCount = async () => {
      if (!socket) {
        await initializeSocket();
        socket = getSocket();
        if (!socket) return;
      }
      socket.on(`notification-count-${decoded?.role == 'super_admin' ? 'null' : decoded?.user_id}`, (data) => {
          setNotificationCount(data);
      });
    };
    readCount();

    if (socket) {
      return () => {
        socket.off(`notification-count-${decoded?.role == 'super_admin' ? 'null' : decoded?.user_id}`);
      };
    }
  }, []);
  return (
    <>
      <Header style={{ background: "#fff", padding: "0px 16px" }}>
        <div className="text-right">
          <Menu mode="horizontal" className="ml-auto flex justify-end">
            <Badge
              count={notificationCount}
              className="noti-icon cursor-pointer"
              onClick={showNotificationDrawer}
            >
              <BellOutlined />
            </Badge>
            <Menu.SubMenu
              key="SubMenu"
              title={
                <>
                  <span
                    className="cursor-pointer inline-block"
                    onClick={(e) => e.preventDefault()}
                  >
                    {decoded?.profile_picture ? (
                      <Avatar
                        src={decoded?.profile_picture}
                        style={{ marginRight: "5px" }}
                      />
                    ) : (
                      <Avatar
                        icon={<UserOutlined />}
                        style={{ marginRight: "5px" }}
                      />
                    )}
                    <span className="mx-2 capitalize">
                      {decoded?.full_name}
                    </span>
                    <DownOutlined />
                  </span>
                </>
              }
            >
              <Menu.Item onClick={() => navigate("/account-settings")}>
                <UserOutlined className="mr-1" /> Profile
              </Menu.Item>
              <Menu.Item onClick={showChangePasswordModal}>
                <UnlockOutlined className="mr-1" /> Change Password
              </Menu.Item>
              <Menu.Item onClick={showLogoutModal}>
                <LoginOutlined className="mr-1" /> Logout
              </Menu.Item>
            </Menu.SubMenu>
          </Menu>
        </div>
      </Header>
      {isChangePasswordModalOpen && (
        <ChangePassword
          isOpen={true}
          onOk={handleChangePasswordOk}
          onCancel={handleChangePasswordCancel}
        />
      )}
      {isLogoutModalOpen && (
        <ConfirmationModal
          ConfirmationHeading="Logout"
          ConfirmationParagraph="Are you sure you want to logout?"
          isOpen={true}
          onOk={handleLogoutOk}
          onCancel={handleLogoutCancel}
        />
      )}
      {notificationDrawerOpen && (
        <Notification isOpen={true} onClose={handleNotificationDrawerClose} />
      )}
    </>
  );
};

export default HeaderComponent;
