import { useNavigate } from "react-router-dom";
import { Card, Divider, Typography, Col, Row, Form } from "antd";
import { LeftOutlined, UploadOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import AgentAuthorization from "./ViewAgentTab/AgentAuthorization";
import AgentPolicy from "./ViewAgentTab/AgentPolicy";
import { useState } from "react";
const { TabPane } = Tabs;
const ViewAgent = () => {
  const navigate = useNavigate();
  const [uploadedImages, setUploadedImages] = useState("");
  return (
    <>
      <Card>
        <div className="flex justify-between items-center mb-4 gap-3">
          <div>
            <Typography className="text-xl font-semibold" align={"left"}>
              <LeftOutlined onClick={() => navigate(-1)} className="mr-2" />
              Agent Detail
            </Typography>
          </div>
        </div>
        <Divider />
        <div className="w-full">
          <div className="rounded-md p-4 border flex flex-col gap-1">
            <div className="flex mb-8">
              <div className=""></div>
              <div>
                <Row gutter={[40, 16]}>
                <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                  >
                  <Form.Item>
                    <div className="flex">
                      <div className="custom-file-upload cursor-pointer">
                        <label
                          htmlFor="file-upload"
                          className="flex flex-col items-center justify-center "
                        >
                          {uploadedImages ? (
                            <img
                              src={uploadedImages}
                              alt="profile-image"
                              className="!w-[140px] h-[140px] rounded-md flex flex-col items-center justify-center text-gray-600 hover:text-gray-800"
                            />
                          ) : (
                            <>
                              <UploadOutlined className="mx-0 mb-2 text-[24px]" />
                              Click to Upload
                              <input id="file-upload" type="file" multiple />
                            </>
                          )}

                          {/* after upload  */}
                          {/* <div>
                  <img
                    alt="profile-image"
                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                    className="!w-[140px] h-[140px] rounded-md flex flex-col items-center justify-center text-gray-600 hover:text-gray-800"
                  />
                  <CloseOutlined
                    className="cursor-pointer absolute right-1 top-1 p-[5px] rounded-full bg-red-600 text-white"
                  />
                </div> */}
                        </label>
                      </div>

                      {/* <div className="text-base self-center ml-[15px] font-medium">
                        Admin Name
                      </div> */}
                    </div>
                  </Form.Item>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[150px]">
                      Agent ID :
                    </div>
                    <div className="text-base">12345</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[150px]">
                      Agent name :
                    </div>
                    <div className="text-base">Rosy Smith</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[150px]">
                      Email address :
                    </div>
                    <div className="text-base">rosy@gmail.com</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[150px]">
                      Mobile number :
                    </div>
                    <div className="text-base">+1 -123456789</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[150px]">
                      Age :
                    </div>
                    <div className="text-base">25 Years</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[150px]">
                      Income :
                    </div>
                    <div className="text-base">Income</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[150px]">
                      Joined on :
                    </div>
                    <div className="text-base">01/02/2024</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[150px]">
                      Last updated on :
                    </div>
                    <div className="text-base">02/02/2024</div>
                  </Col>
                </Row>
              </div>
            </div>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Policy" key="1">
                <AgentPolicy />
              </TabPane>
              <TabPane tab="Authorization Logs" key="2">
                <AgentAuthorization />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ViewAgent;
