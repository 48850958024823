import { Navigate, Outlet } from "react-router-dom"
import addDeleteGetLocalStorage from "../Axios/addDeleteGetLocalStorage"
import { storageKeys } from "../Axios/Enum"
import { getFirstPermittedRoute, loginData } from "../Helpers/Functions"
const LoginGuard = () => {
    let userData = loginData()

    return <>
        {(addDeleteGetLocalStorage(storageKeys.ADMIN_TOKEN , {}, "get", "single")) ? (userData?.resetPassword == 0 ? <Navigate to={'/reset-password'} /> : <Navigate to={getFirstPermittedRoute()} /> ): <Outlet />}
    </>
}

export default LoginGuard