import { Navigate, Outlet} from "react-router-dom"
import addDeleteGetLocalStorage from "../Axios/addDeleteGetLocalStorage"
import { storageKeys } from "../Axios/Enum"
import { getFirstPermittedRoute, loginData } from "../Helpers/Functions"
const ResetPasswordGuard = () => {

    let userData = loginData()

    return <>
        {(addDeleteGetLocalStorage(storageKeys.ADMIN_TOKEN , {}, "get", "single"))   ? (userData?.reset_password == 0 ? <Outlet /> : <Navigate to={getFirstPermittedRoute()} />) : <Navigate to="/" />}
    </>
}


export default ResetPasswordGuard;
