import { Pagination, Select } from "antd";
import SortingArrow from "../../../Common/SortingArrow";
import Assign from "../../../Modal/Assign";
import { useEffect, useState } from "react";
import DateHelper from "../../../Helpers/lib/DateHelper";
import {
  AssignAgent,
  AssignSelf,
  getAgentOptions,
} from "../../../Axios/services/ManageRequest";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../Redux/reducers/loader";
import addDeleteGetLocalStorage from "../../../Axios/addDeleteGetLocalStorage";
import { storageKeys } from "../../../Axios/Enum";
import { jwtDecode } from "jwt-decode";
import { getSocket, initializeSocket } from "../../../Socket/socket";
import EmptyComponent from "../../Empty";
const selectOption = [
  { value: "1", label: "Pick" },
  { value: "2", label: "Assign" },
];
const RequestQueue = ({
  responceData,
  handlePageChange,
  pageCount,
  page,
  itemTotal,
  limit,
  setLimit,
  handlegetRequest,
  handleSort,
}) => {
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [agents, setAgents] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState(null);
  const [requestedId, setRequestedId] = useState(null);
  let token = addDeleteGetLocalStorage(
    storageKeys.ADMIN_TOKEN,
    {},
    "get",
    "single"
  );
  token = jwtDecode(token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAssignOk = () => {
    setIsAssignModalOpen(false);
  };
  const handleAssignCancel = () => {
    setIsAssignModalOpen(false);
  };

  const handlegetAssignAgent = async () => {
    dispatch(changeLoader(true));
    try {
      let responce = await getAgentOptions();
      if (responce.ack) {
        setAgents(responce?.data);
      }
      dispatch(changeLoader(false));
    } catch (err) {
      dispatch(changeLoader(false));
      console.error(err);
    }
  };

  const handleAssign = async () => {
    try {
      let data = {
        request_id: requestedId,
        agent_id: selectedRadio,
      };
      let responce = await AssignAgent(data);
      if (responce.ack) {
        setIsAssignModalOpen(false);
        handlegetRequest();
      }
      toast[responce.ack ? "success" : "error"](responce.message, {
        limit: 1,
        toastId: "forgotPassword" + (responce.ack ? "Success" : "Error"),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handlePick = async (id) => {
    try {
      let data = {
        request_id: id,
      };
      let responce = await AssignSelf(data);
      if (responce.ack) {
        navigate(`/view-request/${id}`);
        handlegetRequest();
      }
      toast[responce.ack ? "success" : "error"](responce.message, {
        limit: 1,
        toastId: "forgotPassword" + (responce.ack ? "Success" : "Error"),
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let socket = getSocket();
    const readCount = async () => {
      if (!socket) {
        await initializeSocket();
        socket = getSocket();
        if (!socket) return;
      }
      socket?.on("new-request", (data) => {
        if (data) {
          handlegetRequest();
          // const audio = new Audio(newOrderSound);
          // audio.play();
        }
      });
    };
    readCount();

    if (socket) {
      return () => {
        socket?.off("new-request");
      };
    }
  }, []);

  return (
    <>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 bg-gray-50">
            <tr>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Request ID{" "}
                <SortingArrow
                  onSort={(order) => {
                    handleSort(order, "request_id");
                  }}
                />
              </th>
              <th scope="col" className="px-3 py-3">
                Client Name{" "}
                <SortingArrow
                  onSort={(order) => {
                    handleSort(order, "full_name");
                  }}
                />
              </th>
              <th scope="col" className="px-3 py-3">
                Date{" "}
                <SortingArrow
                  onSort={(order) => {
                    handleSort(order, "createdAt");
                  }}
                />
              </th>
              <th scope="col" className="px-3 py-3">
                Policy{" "}
                <SortingArrow
                  onSort={(order) => {
                    handleSort(order, "policy_name");
                  }}
                />
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-center whitespace-nowrap"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {responceData?.length > 0 ? (
              responceData?.map((item, index) => (
                <tr className="bg-white border-b" key={index}>
                  <th
                    scope="row"
                    className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top"
                  >
                    {item?.request_id}
                  </th>
                  <td className="px-3 py-4 vertical-top">
                    <p className="font-semibold text-[#000]">
                      {item?.client?.full_name}
                    </p>
                    <p className="font-semibold text-[#000]">
                      {item?.client?.email}
                    </p>
                  </td>
                  <td className="px-3 py-4 vertical-top">
                    {DateHelper.format(item?.createdAt, "m/d/Y h:i A")}
                  </td>
                  <td className="px-3 py-4 vertical-top">
                    {item?.policy?.policy_name}
                  </td>
                  <td className="px-3 py-4 text-center whitespace-nowrap vertical-top">
                    <span
                      className="underline cursor-pointer mx-1 font-semibold"
                      onClick={() => {
                        handlePick(item?.id);
                      }}
                    >
                      Pick
                    </span>
                    {token?.role == "super_admin" && (
                      <span
                        className="underline cursor-pointer mx-1 font-semibold"
                        onClick={() => {
                          setIsAssignModalOpen(true);
                          setRequestedId(item?.id);
                          handlegetAssignAgent();
                        }}
                      >
                        Assign
                      </span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="bg-white border-b">
                <td colSpan={5} className="text-center">
              <EmptyComponent description={"No Request Found"} />
              </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="text-right mt-4">
        <div className="flex justify-end mt-4">
          {pageCount > 1 && (
            <div className="flex justify-end mt-4">
              <Pagination
                defaultCurrent={page}
                defaultPageSize={limit}
                total={itemTotal}
                onChange={handlePageChange}
                pageSizeOptions={[10, 25, 50, 100]}
                onShowSizeChange={(current, size) => {
                  limit = size;
                  setLimit(limit);
                  handlegetRequest();
                }}
              />
            </div>
          )}
        </div>
      </div>

      <Assign
        agents={agents}
        handleAssign={handleAssign}
        isOpen={isAssignModalOpen}
        onOk={handleAssignOk}
        onCancel={handleAssignCancel}
        selectedRadio={selectedRadio}
        setSelectedRadio={setSelectedRadio}
      />
    </>
  );
};

export default RequestQueue;
