import { Card, Col, Collapse, Divider, Row, Typography } from "antd";
const AgentPolicy = () => {
  const { Panel } = Collapse;

  return (
    <>
      <div className="flex justify-between items-center my-5">
        <div className="flex gap-5 mr-[10px]">
          <div className="font-semibold">Associated Agent</div>
          <div className="">Maxwell Sandgross (NPN - 555555)</div>
        </div>
      </div>
      <Collapse accordion bordered={false} className="policy-drop">
        <Panel
          header={
            <div className="flex">
              <div className="mr-3">
                <img src="images/auto.jpg" alt="Auto" />
              </div>
              <div className="flex items-start justify-between w-full">
                <div>
                  <div className="font-semibold mb-1">
                    Policy name: policy_name
                  </div>
                  <div className="font-semibold mb-1">
                    Policy ID: policy_number
                  </div>
                  <div className="font-semibold mb-1">
                    Subscriber: Subscriber
                  </div>
                  <div className="font-semibold mb-1">
                    Effective coverage date: Effective coverage date
                  </div>
                </div>
                <div className="font-semibold">Member: Member</div>
              </div>
            </div>
          }
        >
          <Card>
            <div className="flex justify-between items-center mb-4 gap-3">
              <Typography className="text-xl font-semibold" align={"left"}>
                Policy Detail
              </Typography>
            </div>
            <Divider />
            <div className="w-full">
              <img
                src="images/digital-card.jpg"
                alt="Digital-card"
                className="w-[150px] mb-[14px] object-contain"
              />
              <div className="flex mb-8">
                <Row gutter={[40, 16]}>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      ID :{" "}
                    </div>
                    <div className="text-base">#1234567</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      Subscriber :{" "}
                    </div>
                    <div className="text-base">jane doe</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      Member :{" "}
                    </div>
                    <div className="text-base">jane doe</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      Effective coverage date :{" "}
                    </div>
                    <div className="text-base">Date</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      Premium :{" "}
                    </div>
                    <div className="text-base">$480.00</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      Premium Due :{" "}
                    </div>
                    <div className="text-base">$480.00</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      Max out of pocket :{" "}
                    </div>
                    <div className="text-base">$4080.00</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      Deductible :{" "}
                    </div>
                    <div className="text-base">$0.00</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      Doctor's Visit :{" "}
                    </div>
                    <div className="text-base">$0.00</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      Specialist :{" "}
                    </div>
                    <div className="text-base">$480.00</div>
                  </Col>
                  <Col
                    className="gutter-row flex items-start justify-start"
                    xs={24}
                    sm={24}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    <div className="text-base font-medium text-black w-[250px]">
                      Subsidy :{" "}
                    </div>
                    <div className="text-base">$480.00</div>
                  </Col>
                </Row>
              </div>
            </div>
          </Card>
          <Collapse accordion bordered={false}>
            <Panel header="dependent 1 - gill smith" key="2">
              <Card>
                <div className="flex justify-between items-center mb-4 gap-3">
                  <Typography className="text-xl font-semibold" align={"left"}>
                    Policy Detail
                  </Typography>
                </div>
                <Divider />
                <div className="w-full">
                  <img
                    src="images/no-digital-card.jpg"
                    alt="Digital-card"
                    className="w-[150px] mb-[14px] object-contain"
                  />
                  <div className="flex mb-8">
                    <Row gutter={[40, 16]}>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          ID :{" "}
                        </div>
                        <div className="text-base">#U3728613456</div>
                      </Col>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          Subscriber :{" "}
                        </div>
                        <div className="text-base">Rosy Smith</div>
                      </Col>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          Member :{" "}
                        </div>
                        <div className="text-base">Rosy Smith</div>
                      </Col>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          Effective Date of coverage :{" "}
                        </div>
                        <div className="text-base">$480.00</div>
                      </Col>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          Premium :{" "}
                        </div>
                        <div className="text-base">$480.00</div>
                      </Col>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          Premium Due :{" "}
                        </div>
                        <div className="text-base">$0.00</div>
                      </Col>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          Max out of pocket :{" "}
                        </div>
                        <div className="text-base">$3,800.00</div>
                      </Col>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          Deductible :{" "}
                        </div>
                        <div className="text-base">$0.00</div>
                      </Col>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          Doctor's Visit :{" "}
                        </div>
                        <div className="text-base">$0.00</div>
                      </Col>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          Specialist :{" "}
                        </div>
                        <div className="text-base">$40.00</div>
                      </Col>
                      <Col
                        className="gutter-row flex items-start justify-start"
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                        xl={12}
                      >
                        <div className="text-base font-medium text-black w-[250px]">
                          Subsidy :{" "}
                        </div>
                        <div className="text-base">$488.00</div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Panel>
          </Collapse>
        </Panel>
      </Collapse>
    </>
  );
};

export default AgentPolicy;
