import { Navigate } from "react-router-dom";
import { permissionsForRoutes } from "../Helpers/Functions";
const PermissionRoute = ({ children, roles }) => {
  const routePermisson = permissionsForRoutes(roles);
  if (!routePermisson) {
    // Redirect to "Page Not Found" if the user doesn't have the required role
    return <Navigate to="/page-not-found" />;
  }
  return children;
};

export default PermissionRoute;
