import { io } from 'socket.io-client'
import { baseURL_socket_devlopment, storageKeys , baseURL_socket_stage } from '../Axios/Enum'
import addDeleteGetLocalStorage from '../Axios/addDeleteGetLocalStorage'

let socket
const initializeSocket = async () => {
  if (!socket) {
    socket = io((baseURL_socket_devlopment ) , {
      extraHeaders: {
        'x-token' : addDeleteGetLocalStorage(storageKeys.ADMIN_TOKEN, {}, 'get', 'single')
      }
    })
  }
}

const getSocket = () => socket


const disconnectSocket = () => {
  socket.disconnect()
  socket = null
}

export { getSocket, initializeSocket, disconnectSocket }
