import { configureStore } from "@reduxjs/toolkit";
import loader from "./loader";
import permissions from "./permissions";
import readchatMessage from "./readchatMessage";
import readchatAgentMessage from "./readchatAgentMessage";
import trigger from "./trigger";
import permissionChange from "./permissionChange";

export default configureStore({
  reducer: {
    loader: loader,
    permissions : permissions,
    readchatMessage : readchatMessage,
    readchatAgentMessage : readchatAgentMessage,
    trigger : trigger,
    permissionChange : permissionChange
  },
});
