import React, { useEffect, useState } from "react";
import {
  Switch,
  Tooltip,
  Pagination,
  Card,
  Typography,
  Input,
  Empty,
  Form,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import SortingArrow from "../../Common/SortingArrow";
import { UpdateCmsStatus, getCmsData } from "../../Axios/services/Cms";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../Redux/reducers/loader";
import { toast } from "react-toastify";
import EmptyComponent from "../Empty";

import DateHelper from "../../Helpers/lib/DateHelper";
import { strFormat } from "../../Helpers/Functions/index";
const CMSPage = () => {
  const navigate = useNavigate();
  const { Search } = Input;
  const [responce, setResponce] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let [page, setPage] = useState(1);
  let [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({
    order: "ASC",
    sort_by: "title",
  });

  let [limit, setLimit] = useState(3);
  let [pageCount, setPageCount] = useState(0);
  const [itemTotal, setItemTotal] = useState(0);
  const getCms = async () => {
    dispatch(changeLoader(true));
    try {
      let params = {
        ...sorting,
      };
      if (search) {
        params["search"] = search.trim();
      }
      const response = await getCmsData(params);
      if (response.ack) {
        setResponce(response?.data);
        setItemTotal(response?.data?.count);
        setPageCount(Math.ceil(response?.data?.count / limit));
      }
      toast[response.ack ? "success" : "error"](response.message, {
        limit: 1,
        toastId: "forgotPassword" + (response.ack ? "Success" : "Error"),
      });
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
      console.log(error);
    }
  };

  const handleSort = (order, sortby) => {
    let obj = {
      order: order,
      sort_by: sortby,
    };
    setSorting(obj);
  };

  const handlePageChange = (newPage) => {
    page = newPage;
    setPage(newPage);
    getCms();
  };

  const handleUpdateStatus = async (id, item) => {
    dispatch(changeLoader(true));
    try {
      let params = {
        status: item === "active" ? "inactive" : "active",
      };
      let responce = await UpdateCmsStatus(id, params);
      if (responce.ack) {
        getCms();
      }
      toast[responce.ack ? "success" : "error"](responce.message, {
        limit: 1,
        toastId: "forgotPassword" + (responce.ack ? "Success" : "Error"),
      });
      dispatch(changeLoader(false));
    } catch (error) {
      dispatch(changeLoader(false));
      console.log(error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getCms();
    }, 400);
    return () => clearTimeout(timer);
  }, [sorting, search]);

  return (
    <>
      <Card>
        <div className="flex justify-between	mb-4 gap-3">
          <Typography className="text-xl mb-0 font-semibold" align={"left"}>
            CMS
          </Typography>
          <div className="flex justify-between gap-3">
            <Form form={form} layout="vertical">
              <Form.Item name="search">
                <Search
                  allowClear
                  placeholder="Search by Name"
                  style={{
                    width: 250,
                  }}
                  className="cursor-pointer"
                  onChange={(e) => {
                    let value = strFormat(e.target.value, "trim");
                    form.setFieldValue("search", value);
                    setSearch(value);
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="relative overflow-x-auto">
          <table className="w-full text-sm text-left  text-gray-500">
            <thead className="text-xs text-gray-700 bg-gray-50">
              <tr>
                <th scope="col" className="px-3 py-3 whitespace-nowrap">
                  Page name{" "}
                  <SortingArrow
                    onSort={(order) => {
                      handleSort(order, "title");
                    }}
                  />
                </th>
                <th scope="col" className="px-3 py-3">
                  Updated on
                </th>
                {/* <th scope="col" className="px-3 py-3">
                  Status
                </th> */}
                <th
                  scope="col"
                  className="px-3 py-3 text-center whitespace-nowrap"
                >
                  Action
                </th>
              </tr>
            </thead>

            <tbody className="text-xs text-gray-700 bg-white border-b">
              {responce?.length > 0 ? (
                responce?.map((item) => (
                  <tr>
                    <th
                      scope="row"
                      className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {item?.title}
                    </th>
                    <td className="px-3 py-4">
                      {DateHelper.format(item?.updatedAt, "m/d/Y h:i A")}
                    </td>
                    {/* <td className="px-3 py-4 vertical-top">
                      <Switch
                        defaultChecked={item?.status === "active" ? true : false}
                        onChange={() => {
                          handleUpdateStatus(item?.slug, item?.status);
                        }}
                      />
                    </td> */}
                    <td className="px-3 py-4 text-center whitespace-nowrap">
                      <Tooltip title="Edit">
                        <EditOutlined
                          onClick={() => navigate(`/cms-editor/${item?.slug}`)}
                          className="mx-1.5 cursor-pointer"
                        />
                      </Tooltip>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center">
                    <EmptyComponent description="No data found" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pageCount > 1 && (
          <div className="text-right mt-4">
            <Pagination
              defaultCurrent={page}
              defaultPageSize={limit}
              total={itemTotal}
              onChange={handlePageChange}
              pageSizeOptions={[10, 25, 50, 100]}
              onShowSizeChange={(current, size) => {
                limit = size;
                setLimit(limit);
                getCms();
              }}
            />
          </div>
        )}
      </Card>
    </>
  );
};

export default CMSPage;
