import { createSlice } from "@reduxjs/toolkit";

export const permissionChange = createSlice({
  name: "permissionChange",
  initialState: {
    value: false,
  },
  reducers: {
    changePermissonChange: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changePermissonChange } = permissionChange.actions;
export const currentPermissionChange = (state) => state.permissionChange.value;
export default permissionChange.reducer;
