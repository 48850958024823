import axiosInstance from "../AxiosInstance";

export const getCmsData = (params) => {
    return axiosInstance.get('/admin/pages/getAllPages' , {params})
}

export const UpdateCmsStatus = (slug,data) => {
    return axiosInstance.put(`/admin/pages/update-status/${slug}`, data)
}

export const getCmsDetail = (slug) => {
    return axiosInstance.get(`/admin/pages/get/${slug}`)
}

export const updatePage = (slug,data) => {
    return axiosInstance.put(`/admin/pages/update/${slug}`, data)
}