import axiosInstance from "../AxiosInstance"

export const getchatRoom = (id) => {
    return axiosInstance.get(`/admin/chat/get/${id}`, )
}

export const getChatRoomMessage = (id) => {
    return axiosInstance.get(`/admin/chat/get/messages/${id}`, )
}

export const getChatMessageRead = (id) => {
    return axiosInstance.get(`/admin/chat/mark-read/${id}`, )
}

export const sendMessage = (data) => {
    return axiosInstance.post(`/admin/chat/send`, data)
}

export const closeChat = (id) => {
    return axiosInstance.delete(`/admin/chat/closeChat/${id}`)
}

export const chatMessageCount = () => {
    return axiosInstance.get(`/admin/chat/get-unread-count`)
}