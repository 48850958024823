
import axios from "axios";

const fileAzurUpload = (file) => {
  return  axios.post('https://myclearapp-file-api-hmexebhpcbczczc5.eastus-01.azurewebsites.net/files/upload', file, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'X-Api-Key' : '9CAawJ8M0MeDz/je0xLpLwxetgPUOH703wRWDmedQng='
        }
    })
}

export default fileAzurUpload