import { Button, Divider, Modal, Radio } from "antd";
import { useState } from "react";

const Assign = ({ isOpen, onOk, onCancel , agents , setSelectedRadio , selectedRadio , handleAssign }) => {

  return (
    <>
      <Modal
        centered
        open={isOpen}
        onCancel={onCancel}
        onOk={onOk}
        width={600}
        footer={null}
      >
        <div className="flex flex-col gap-1">
          <div className="flex justify-center items-center">
            <div className="font-semibold text-lg">Assign Agent</div>
          </div>
          <div className="w-full ">
            <div className="pt-4 px-2 max-h-[calc(100vh_-_200px)] overflow-y-auto">
              {agents?.length > 0 && agents?.map((item) => (
                <>
                  <Radio
                    key={item?.id}
                    className="w-full hover:bg-slate-100 rounded-md overflow-hidden p-3"
                    value={item?.id}
                    name="radio"
                    checked={selectedRadio === item?.id}
                    onChange={(e) => setSelectedRadio(e.target.value)}
                  >
                    {item?.full_name || item?.agent_name }
                  </Radio>
                  <Divider className="my-0.5" />
                  </>
                ))
              }
            </div>
          </div>
          <div className="text-center mt-[20px]">
                <Button type="primary" onClick={handleAssign} disabled={selectedRadio ? false : true} >Assign</Button>
              </div>
        </div>
      </Modal>
    </>
  );
};
export default Assign;
