import {
  Button,
  Card,
  Col,
  Avatar,
  Divider,
  List,
  Row,
  Typography,
  Input,
  Form,
} from "antd";
import { SendOutlined, LeftOutlined, UserOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkPermission, strFormat } from "../../Helpers/Functions";
import CloseChat from "../../Modal/CloseChat";
import {
  getAllChatRooms,
  getchatRoom,
  getChatRoomMessage,
  getUnreadCount,
  markchatMessageAsRead,
  sendMessage,
} from "../../Axios/services/InsuranceAgentChat";
import EmptyComponent from "../Empty";
import { useDispatch, useSelector } from "react-redux";
import { changeLoader } from "../../Redux/reducers/loader";
import addDeleteGetLocalStorage from "../../Axios/addDeleteGetLocalStorage";
import { storageKeys } from "../../Axios/Enum";
import { jwtDecode } from "jwt-decode";
import DateHelper from "../../Helpers/lib/DateHelper";
import { getSocket, initializeSocket } from "../../Socket/socket";
import { changeAgentReadchatMessage } from "../../Redux/reducers/readchatAgentMessage";
import { changeTrigger } from "../../Redux/reducers/trigger";

const InsuranceAgentView = () => {
  const navigate = useNavigate();
  const { Search } = Input;
  const onSearch = (value) => console.log(value);
  const [isViewChatLogModalOpen, setIsViewChatLogModalOpen] = useState(false);
  const [chatRoomId, setChatRoomId] = useState(null);
  const [message, setMessage] = useState("");
  const [allChatRooms, setAllChatRooms] = useState([]);
  const trigger = useSelector((state) => state?.trigger?.value);

  const [form] = Form.useForm();
  const [count, setCount] = useState(0);
  const [type, setType] = useState("load");
  const [search, setSearch] = useState("");
  const chatContainerRef = useRef(null);
  const [sorting, setSorting] = useState({
    order: "DESC",
    sort_by: "updatedAt",
  });

  const scrollToBottom = () => {
    chatContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const token = addDeleteGetLocalStorage(
    storageKeys.ADMIN_TOKEN,
    {},
    "get",
    "single"
  );
  const role = jwtDecode(token)?.role;
  const user_id = token ? jwtDecode(token)?.user_id : 0;
  let [chatRoomResponces, setChatRoomResponces] = useState({
    chatRoomData: {},
    chatMessagesData: [],
  });
  const dispatch = useDispatch();
  const handleViewChatLogOk = () => {
    setIsViewChatLogModalOpen(false);
  };
  const handleViewChatLogCancel = () => {
    setIsViewChatLogModalOpen(false);
  };

  const handlegetgetAllChatRooms = async () => {
    try {
      let parms = { ...sorting};
      if (search) {
        parms["search"] = search;
      }
      let res = await getAllChatRooms(parms);
      if (res.ack) {
        setAllChatRooms(res?.data?.rows);
        if (type == "load") {
          setChatRoomId(res?.data?.rows[0]?.id);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    try {
      let data = {
        chat_room_id: chatRoomId,
        message: message,
        receiver_id: chatRoomResponces?.chatRoomData?.id,
      };
      let responce = await sendMessage(data);
      if (responce.ack) {
        setChatRoomResponces((prev) => {
          return {
            ...prev,
            chatMessagesData: [...prev.chatMessagesData, responce?.data],
          };
        });
        setTimeout(() => {
          scrollToBottom();
        }, 400);
        setMessage("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlegetchatRoom = async (id) => {
    if (trigger !== "countValueChanged") {
      return await getchatRoom(id);
    }
  };

  const handlegetChatRoomMessage = async (id) => {
    if (trigger !== "countValueChanged") {
      return await getChatRoomMessage(id);
    }
  };

  const handlemarkchatMessageAsRead = async (id) => {
    return await markchatMessageAsRead(id);
  };

  const handleGetChatRoom = async (id) => {
    type == "load" && dispatch(changeLoader(true));
    try {
      let [chatroomResponce, chatmessageResponce, markchatMessageResponce] =
        await Promise.all([
          handlegetchatRoom(id),
          handlegetChatRoomMessage(id),
          handlemarkchatMessageAsRead(id),
        ]);
      if (
        chatroomResponce?.ack &&
        chatmessageResponce?.ack &&
        markchatMessageResponce?.ack
      ) {
        setChatRoomResponces({
          chatRoomData: chatroomResponce?.data,
          chatMessagesData: chatmessageResponce?.data?.rows,
        });
        dispatch(changeAgentReadchatMessage(true));
      }
    } catch (err) {
      console.log(err);
    }
    type == "load" && dispatch(changeLoader(false));
  };

  useEffect(() => {
    if (allChatRooms?.length > 0 && type == "load") {
      if (trigger !== "countValueChanged") {
        const firstChatRoom = allChatRooms[0];
        handleGetChatRoom(firstChatRoom?.id);
      }
    }
  }, [allChatRooms, type]);

  useEffect(() => {
    let socket = getSocket();
    const chatMessages = async () => {
      if (!socket) {
        await initializeSocket();
        socket = getSocket();
        if (!socket) return;
      }
      socket?.on(`agent-new-chat-message-${chatRoomId}`, (data) => {
        if (data && data?.chat_message?.room_id === chatRoomId) {
          socket?.emit("agent-mark-as-read", chatRoomId);
          if (chatRoomId) {
            dispatch(changeAgentReadchatMessage(true));
          }
        }
        if (data?.chat_message?.room_id === chatRoomId) {
          setChatRoomResponces((prev) => {
            return {
              ...prev,
              chatMessagesData: [...prev.chatMessagesData, data?.chat_message],
            };
          });
          setTimeout(() => {
            scrollToBottom();
          }, 400);
        }
      });
    };

    chatMessages();

    if (socket) {
      return () => {
        socket?.off(`agent-new-chat-message-${chatRoomId}`);
        socket?.off(`agent-mark-as-read`);
      };
    }
  }, [chatRoomResponces, chatRoomId]);

  useEffect(() => {
    let socket = getSocket();
    const chatMessages = async () => {
      if (!socket) {
        await initializeSocket();
        socket = getSocket();
        if (!socket) return;
      }
      socket?.on(`agent-new-chat-room-${user_id}`, (data) => {
        if (data) {
          console.log("agent-new-chat-room", data);
          setAllChatRooms((prev) => {
            return [...prev, data?.chat_room];
          });
        }
      });
      if (role == "super_admin") {
        socket?.on("agent-new-chat-room", (data) => {
          if (data) {
            console.log("agent-new-chat-room", data);
            handleGetChatRoom(data?.chat_room?.id);
          }
        });
      }
    };
    chatMessages();
    if (socket) {
      return () => {
        socket?.off(`agent-new-chat-room-${user_id}`);

        if (role == "super_admin") {
          socket?.off("agent-new-chat-room");
        }
      };
    }
  }, [allChatRooms]);

  // 2 times api call to remove count
  useEffect(() => {
    if (chatRoomId) {
      let chatrooms = [...allChatRooms];
      for (let index in chatrooms) {
        let chatRoom = chatrooms[index];
        if (chatRoom.id == chatRoomId) {
          chatrooms[index].total_messages = 0;
          setType("notReload");
          setAllChatRooms(chatrooms);
          break;
        }
      }
    }
  }, [chatRoomId]);

  useEffect(() => {
    if (trigger === "countValueChanged") {
      handleGetChatRoom(chatRoomId);
      handlegetgetAllChatRooms();
      dispatch(changeTrigger(""));
    }
  }, [trigger]);

  useEffect(() => {
    scrollToBottom();
  }, [chatRoomResponces?.chatMessagesData]);

  useEffect(() => {
    let timer = setTimeout(() => {
      handlegetgetAllChatRooms();
    }, 400);
    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  return (
    <>
      <Card>
        <div className="min-h-[calc(100vh_-_290px)] editor-height">
          <div className="flex items-center justify-between mb-4">
            <Typography className="text-lg font-semibold" align={"left"}>
              Messages
            </Typography>
          </div>
          <Row>
            <Col span={6}>
              <div className="Chat-list pt-5 min-h-[calc(100vh_-_150px)]">
                {chatRoomResponces?.chatMessagesData?.length > 0 && (
                  <Form form={form} layout="vertical">
                    <Form.Item name="search">
                      <Search
                        allowClear
                        placeholder="Search by Client Name"
                        className="cursor-pointer w-full max-w-[calc(100%_-_10px)]"
                        onChange={(e) => {
                          let value = strFormat(e.target.value, "trim");
                          form.setFieldValue("search", value);
                          setSearch(value);
                        }}
                      />
                    </Form.Item>
                  </Form>
                )}
                <div className="scrollable-list">
                  <ul className="custom-chat-list">
                    {allChatRooms?.length > 0 ? (
                      allChatRooms?.map((item, index) => (
                        <li
                          className={`list-item has-message cursor-pointer ${
                            chatRoomId === item?.id
                              ? "bg-[#0dbff1] text-white"
                              : ""
                          }`}
                          onClick={() => {
                            handleGetChatRoom(item?.id);
                            setChatRoomId(item?.id);
                            navigate(`/insurance-agent-view?id=${item?.id}`);
                          }}
                        >
                          <div className="list-item-meta">
                            {/* <img
                              className="avatar"
                              src={
                                item?.chat_client?.profile_picture
                                  ? item?.chat_client?.profile_picture
                                  : "https://api.dicebear.com/7.x/miniavs/svg?seed=0"
                              }
                              alt="Avatar"
                            /> */}
                            {item?.chat_client?.profile_picture ? (
                              <img
                                className="avatar"
                                src={item?.chat_client?.profile_picture}
                                alt="Avatar"
                              />
                            ) : (
                              <div className="avatar-default">
                                {`${item?.chat_client?.full_name
                                  .split(" ")[0]
                                  .charAt(0)
                                  .toUpperCase()}${
                                  item?.chat_client?.full_name.split(" ")[1]
                                    ? item?.chat_client?.full_name
                                        .split(" ")[1]
                                        .charAt(0)
                                        .toUpperCase()
                                    : ""
                                }`}
                              </div>
                            )}
                            <div>
                              <div className="title">
                                {item?.chat_client?.full_name}
                              </div>
                              <div className="description">
                                {item?.agent_chat_messages?.[0]?.message}
                              </div>
                            </div>
                          </div>
                          {item?.total_messages > 0 && (
                            <div className="notification-dot">
                              <span>{item?.total_messages}</span>
                            </div>
                          )}
                        </li>
                      ))
                    ) : (
                      <EmptyComponent description="No chat found" />
                    )}
                  </ul>
                </div>
              </div>
            </Col>
            <Col span={18}>
              <div className="mb-0">
                <div className="w-full pt-1">
                  <div id="messages" className="px-4">
                    <div className="Chat-content">
                      <div className="scrollable-list">
                        <ul className="custom-chat-list">
                          <li className="list-item has-message">
                            {chatRoomResponces?.chatMessagesData?.length >
                              0 && (
                              <div className="list-item-meta">
                                {chatRoomResponces &&
                                chatRoomResponces?.chatRoomData?.chat_client
                                  ?.profile_picture ? (
                                  <img
                                    className="avatar"
                                    src={
                                      chatRoomResponces?.chatRoomData
                                        ?.chat_agent?.profile_picture
                                    }
                                    alt="Avatar"
                                  />
                                ) : (
                                  <div className="avatar-default">
                                    {chatRoomResponces &&
                                      chatRoomResponces?.chatRoomData?.chat_client?.full_name
                                        .split(" ")
                                        .map((name) =>
                                          name.charAt(0).toUpperCase()
                                        )
                                        .slice(0, 2)
                                        .join("")}
                                  </div>
                                )}
                                <div>
                                  <div className="title">
                                    {chatRoomResponces &&
                                      chatRoomResponces?.chatRoomData
                                        ?.chat_client?.full_name}
                                  </div>
                                  <div className="description">
                                    {chatRoomResponces &&
                                      chatRoomResponces?.chatRoomData
                                        ?.chat_client?.email}
                                  </div>
                                </div>
                              </div>
                            )}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="h-[calc(100vh_-_290px)] overflow-y-auto pt-3">
                      {/* <Divider className="!m-0">
                        <span className="text-[12px]">May 29, 2024</span>
                      </Divider> */}
                      {chatRoomResponces?.chatMessagesData?.length > 0 ? (
                        chatRoomResponces?.chatMessagesData?.map(
                          (item, index) => {
                            const isUserMessage = user_id === item?.sender_id;

                            return (
                              <div
                                className="mb-4"
                                key={index}
                                ref={chatContainerRef}
                              >
                                <div
                                  className={`flex ${
                                    isUserMessage
                                      ? "justify-end"
                                      : "justify-start"
                                  } items-center`}
                                >
                                  {!isUserMessage && (
                                    <Avatar
                                      size={32}
                                      icon={<UserOutlined />}
                                      className="mr-2"
                                    />
                                  )}
                                  <div
                                    className={`w-auto max-w-[80%] min-w-[100px] break-all p-2 pb-4 rounded-lg relative ${
                                      isUserMessage
                                        ? "bg-[#0dbff1] text-white"
                                        : "bg-slate-100"
                                    }`}
                                  >
                                    <p className="text-sm mb-2">
                                      {item?.message}
                                    </p>
                                    <span
                                      className={`absolute right-2 bottom-1 text-[10px] ${
                                        isUserMessage
                                          ? "text-white"
                                          : "text-[#a8a8a8]"
                                      }`}
                                    >
                                      {DateHelper.format(
                                        item?.createdAt,
                                        "h:i A"
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )
                      ) : (
                        <EmptyComponent description="No chat message found" />
                      )}
                    </div>
                  </div>
                  {/* Input */}
                  {
                    // !(allChatRooms?.length == 0 || !chatRoomId) &&
                    chatRoomResponces?.chatMessagesData?.length > 0 && (
                      <form
                        className="flex p-4 border-t border-gray-300"
                        onSubmit={handleSendMessage}
                      >
                        <input
                          id="messageInput"
                          type="text"
                          placeholder="Type your message here..."
                          className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                          value={message}
                          onChange={(e) =>
                            setMessage(e.target.value?.trimStart())
                          }
                        />
                        <button
                          type="submit"
                          className={`ml-2 px-[14px] rounded-lg text-white ${
                            message
                              ? "bg-[#0dbff1]"
                              : "bg-gray-400 cursor-not-allowed"
                          }`}
                          disabled={!message}
                        >
                          <SendOutlined />
                        </button>
                      </form>
                    )
                  }
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Card>
      <CloseChat
        ConfirmationHeading="Alert"
        ConfirmationParagraph="Are you sure you want to close the chat? This is end the current chat & you cannot reopen it."
        isOpen={isViewChatLogModalOpen}
        onOk={handleViewChatLogOk}
        onCancel={handleViewChatLogCancel}
      />
    </>
  );
};
export default InsuranceAgentView;
