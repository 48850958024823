import { Avatar, Card, Divider, Typography } from "antd";
import { LeftOutlined ,UserOutlined} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  getchatRoom,
  getChatRoomMessage,
} from "../../../../Axios/services/OpenChat";
import { useEffect, useState } from "react";
import addDeleteGetLocalStorage from "../../../../Axios/addDeleteGetLocalStorage";
import { storageKeys } from "../../../../Axios/Enum";
import { jwtDecode } from "jwt-decode";
import { useDispatch } from "react-redux";
import { changeLoader } from "../../../../Redux/reducers/loader";
import DateHelper from "../../../../Helpers/lib/DateHelper";

const ViewChatLog = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const dispatch = useDispatch();
  const [chatRoomResponces, setChatRoomResponces] = useState([]);
  const [closedAt, setClosedAt] = useState(null);
  const token = addDeleteGetLocalStorage(
    storageKeys.ADMIN_TOKEN,
    {},
    "get",
    "single"
  );
  const user_id = token ? jwtDecode(token).user_id : 0;
  const handleGetChatMessage = async () => {
    dispatch(changeLoader(true));
    try {
      let [chatroomResponce, chatMessagesResponce] = await Promise.all([
        getchatRoom(slug),
        getChatRoomMessage(slug),
      ]);
      if (chatroomResponce.ack && chatMessagesResponce.ack) {
        setChatRoomResponces(chatMessagesResponce?.data?.rows);
        setClosedAt(chatroomResponce?.data?.closed_at);
      }
      dispatch(changeLoader(false));
    } catch (err) {
      dispatch(changeLoader(false));
      console.log(err);
    }
  };

  useEffect(() => {
    handleGetChatMessage();
  }, []);

  return (
    <>
      <Card>
        <div className="min-h-[calc(100vh_-_290px)] editor-height">
          <div className="flex items-center justify-between mb-4">
            <Typography className="text-lg font-semibold" align={"left"}>
              <LeftOutlined
                onClick={() => navigate(-1)}
                className="text=lg text-black mr-2 cursor-pointer"
              />
              Chat
            </Typography>
            <div className="flex items-center justify-between gap-3">
              <span className="text-base font-semibold underline text-[#0dbff1]">
                Closed Date & Time :{" "}
                {DateHelper.format(closedAt, "d/m/Y h:i A")}
              </span>
            </div>
          </div>
          <Divider />
          <div className="mb-0">
            <div className="flex flex-col gap-1">
              <div className="w-full ">
                <div id="messages" className="p-4">
                  {chatRoomResponces?.map((item, index) => {
                    const isUserMessage = user_id !== item?.receiver_id;
                    return (
                      <div className="mb-4" key={index}>
                        <div
                          className={`flex ${
                            isUserMessage ? "justify-end" : "justify-start"
                          } items-center`}
                        >
                          {!isUserMessage && (
                            <Avatar size={32} icon={<UserOutlined />} className="mr-2" />   
                          )}
                          <div
                            className={`w-auto max-w-[80%] min-w-[100px] break-all p-2 pb-4 rounded-lg relative ${
                              isUserMessage
                                ? "bg-[#0dbff1] text-white"
                                : "bg-slate-100"
                            }`}
                          >
                            <p className="text-sm mb-2">{item?.message}</p>
                            <span className="absolute right-2 bottom-1 text-[10px] text-[#f6f2f2]">
                              {DateHelper.format(item?.createdAt, "h:i A")}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* <div className="mb-4">
                    <div className="flex justify-start items-center">
                      <img
                        src="images/user.png"
                        alt="Agent Icon"
                        className="w-8 h-8 mr-2 rounded-full"
                      />
                      <div className="bg-slate-100 w-auto max-w-[80%] p-2 pb-4 rounded-lg relative">
                        <p className="text-sm mb-2">message text from client</p>
                        <span className="absolute right-2 bottom-1 text-[10px] text-[#a8a8a8]">
                          10:01 AM
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="flex justify-start items-center">
                      <img
                        src="images/user.png"
                        alt="Agent Icon"
                        className="w-8 h-8 mr-2 rounded-full"
                      />
                      <div className="bg-slate-100 w-auto max-w-[80%] p-2 pb-4 rounded-lg relative">
                        <p className="text-sm mb-2">message text from client</p>
                        <span className="absolute right-2 bottom-1 text-[10px] text-[#a8a8a8]">
                          10:01 AM
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="flex justify-start items-center">
                      <img
                        src="images/user.png"
                        alt="Agent Icon"
                        className="w-8 h-8 mr-2 rounded-full"
                      />
                      <div className="bg-slate-100 w-auto max-w-[80%] p-2 pb-4 rounded-lg relative">
                        <p className="text-sm mb-2">
                          Hello! How can I help you today? Hello! How can I help
                          you today?Hello! How can I help you today?Hello! How
                          can I help you today?Hello! How can I help you
                          today?Hello! How can I help you today?
                        </p>
                        <span className="absolute right-2 bottom-1 text-[10px] text-[#a8a8a8]">
                          10:01 AM
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="flex justify-end items-center">
                      <div className="bg-[#0dbff1] w-auto max-w-[80%] text-white p-2 pb-4 rounded-lg relative">
                        <p className="text-sm mb-2">
                          message text from agent or admin
                        </p>
                        <span className="absolute right-2 bottom-1 text-[10px]">
                          10:01 AM
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <div className="flex justify-end items-center">
                      <div className="bg-[#0dbff1] w-auto max-w-[80%] text-white p-2 pb-4 rounded-lg relative">
                        <p className="text-sm mb-2">
                          I need some assistance.I need some assistance.I need
                          some assistance.I need some assistance.I need some
                          assistance.
                        </p>
                        <span className="absolute right-2 bottom-1 text-[10px]">
                          10:01 AM
                        </span>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
export default ViewChatLog;
