import { createSlice } from "@reduxjs/toolkit";

export const permissions = createSlice({
  name: "permissions",
  initialState: {
    value : {}
  },
  reducers: {
    changePermissions: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changePermissions } = permissions.actions;
export const currentPermissions = (state) => state.permissions.value;
export default permissions.reducer;