import { Pagination, Tooltip } from "antd";
import SortingArrow from "../../../Common/SortingArrow";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import DateHelper from "../../../Helpers/lib/DateHelper";
import EmptyComponent from "../../Empty";

const ChatLogs = ({
  chatQueue,
  handleGetAllchatqueue,
  page,
  limit,
  pageCount,
  itemTotal,
  handlePageChange,
  setLimit,
  handleSort,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 bg-gray-50">
            <tr>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Client ID{" "}
                <SortingArrow
                  onSort={(order) => {
                    handleSort(order, "id");
                  }}
                />
              </th>
              <th scope="col" className="px-3 py-3">
                Client Name{" "}
                <SortingArrow
                  onSort={(order) => {
                    handleSort(order, "client_name");
                  }}
                />
              </th>
              <th scope="col" className="px-3 py-3">
                Email Address{" "}
                <SortingArrow
                  onSort={(order) => handleSort(order, "client_email")}
                />
              </th>
              <th scope="col" className="px-3 py-3">
                Agent{" "}
                <SortingArrow
                  onSort={(order) => handleSort(order, "agent_name")}
                />
              </th>
              <th scope="col" className="px-3 py-3">
                Chat Date & time{" "}
                <SortingArrow
                  onSort={(order) => handleSort(order, "createdAt")}
                />
              </th>
              <th
                scope="col"
                className="px-3 py-3 text-center whitespace-nowrap"
              >
                Action
              </th>
            </tr>
          </thead>

          <tbody>
            {chatQueue?.rows?.length > 0 ? (
              chatQueue?.rows?.map((item) => {
                return (
                  <tr className="bg-white border-b">
                    <th
                      scope="row"
                      className="px-3 py-4 font-medium text-gray-900 whitespace-nowrap vertical-top"
                    >
                      {item?.client_id}
                    </th>
                    <td className="px-3 py-4 vertical-top">
                      {item?.client?.full_name}
                    </td>
                    <td className="px-3 py-4 vertical-top">
                      {item?.client?.email}
                    </td>
                    <td className="px-3 py-4 vertical-top">
                      <p className="text-black font-semibold">
                        {item?.agent?.full_name || "-"}
                      </p>
                      <p>{item?.agent?.email}</p>
                    </td>
                    <td className="px-3 py-4 vertical-top">
                      {DateHelper.format(item?.createdAt, "d/m/Y h:i A") || "-"}
                    </td>
                    <td className="px-3 py-4 text-center vertical-top">
                      <Tooltip title="View Chat Log">
                        <EyeOutlined
                          className="mx-1.5 cursor-pointer"
                          onClick={() => navigate(`/view-chat-log/${item?.id}`)}
                        />
                      </Tooltip>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={6} className="text-center">
                  <EmptyComponent description={"No chat logs found"} />
                </td>{" "}
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="text-right mt-4">
        <div className="flex justify-end mt-4">
          {pageCount > 1 && (
            <div className="flex justify-end mt-4">
              <Pagination
                defaultCurrent={page}
                defaultPageSize={limit}
                total={itemTotal}
                onChange={handlePageChange}
                pageSizeOptions={[10, 25, 50, 100]}
                onShowSizeChange={(current, size) => {
                  limit = size;
                  setLimit(limit);
                  handleGetAllchatqueue();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChatLogs;
