import { createSlice } from "@reduxjs/toolkit";

export const readchatMessage = createSlice({
  name: "readchatMessage",
  initialState: {
    value: false,
  },
  reducers: {
    changeReadchatMessage: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeReadchatMessage } = readchatMessage.actions;
export const currentReadchatMessage = (state) => state.readchatMessage.value;
export default readchatMessage.reducer;
